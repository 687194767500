import React from 'react';
import styles from './index.module.scss';

export default function ArrowButton({
    variant = '',
    color = '',
    onClick,
    href = '#',
    fixed = false,
    fullWidth = false,
    children
}) {
    return (
        <a
            href={href}
            className={`${styles.root} ${styles[variant]} ${
                color ? styles[color] : ''
            } ${fixed ? styles.fixed : ''} ${
                fullWidth ? styles.fullWidth : ''
            }`}
        >
            <div onClick={onClick}>{children}</div>
        </a>
    );
}
