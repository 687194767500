import ArrowButton from '../components/arrow-button';
import Layout from '../components/layout';
import React, { useState } from 'react';
import SEO from '../components/seo';
import TwitchPlayer from '../components/twitch-player';

export default function LivePage() {
    const [darkMode, setDarkMode] = useState(1);
    const [audio, setAudio] = useState(1);
    const sw = {
        setDarkMode,
        setAudio,
        values: { darkMode, audio }
    };
    return (
        <Layout sw={sw}>
            <SEO title="mixes" />
            <ArrowButton variant="back" href="/" fixed>
                Home
            </ArrowButton>
            <TwitchPlayer dark={darkMode} mute={audio} />
        </Layout>
    );
}
