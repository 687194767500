import React, { useEffect, useRef, useState } from 'react';

export default function TwitchPlayer({
    dark = true,
    mute = false
}) {
    const player = useRef();
    const [embed, setEmbed] = useState();
    useEffect(() => {
        if (typeof window !== `undefined`) {
            const playerContainer = player.current;
            const script = document.createElement('script');
            script.src = `https://player.twitch.tv/js/embed/v1.js`;
            script.async = true;
            script.addEventListener('load', () => {
                setEmbed(
                    new window.Twitch.Embed('twitch-embed', {
                        width: '100%',
                        height: '100%',
                        channel: 'nocaptcha_live',
                        theme: dark ? 'dark' : 'light'
                    })
                );
            });
            playerContainer.appendChild(script);

            return () => {
                playerContainer.removeChild(script);
            };
        }
    }, [dark]);
    useEffect(() => {
        if (embed) {
            embed.setMuted(mute);
        }
    }, [embed, mute]);
    return (
        <div
            ref={player}
            id="twitch-embed"
            style={{
                maxWidth: '1920px',
                width: '88vw',
                height: '88vh',
                maxHeight: '1080px',
                marginTop: '4rem'
            }}
        ></div>
    );
}
